/*eslint no-undef: 0*/
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Link } from 'gatsby'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Img from "gatsby-image/withIEPolyfill"
import VideoComponent from './videoComponent'
import axios from 'axios'
import { endpoints } from '../helpers/endpoints'


const LessonCard = ({ title, description, image = {}, youtubeLink, linkMercadopago, linkPaypal, esArgentina = false, paypalPrice, mercadopagoPrice }) => {
    const intl = useIntl()
    const MySwal = withReactContent(Swal)

    const PaymentProcess = (_isPaypal, lesson, linkPaypal, linkMercadopago, paypalPrice, mercadopagoPrice) => {
        const usePaypal = true;
        
        const finalStepHtml = usePaypal? `<a class="mt-6 rounded-lg px-4 py-2 bg-yellow-500" href="${linkPaypal}">Paypal</a> ` : `<a class="mt-6 rounded-lg px-4 py-2 bg-yellow-500" href="${linkMercadopago}">Mercadopago</a> `
        const price = usePaypal? `<span class="my-5 px-2 py-2 rounded-lg border-2 border-flyer-blue font-serif font-semibold">` + intl.formatMessage({ id: "price" }) + `: USD $${paypalPrice}</span>`
            : `<span class="my-5 px-2 py-2 rounded-lg border-2 border-flyer-blue font-serif font-semibold">` + intl.formatMessage({ id: "price" }) + `: ARS $${mercadopagoPrice}</span>`

        MySwal.fire({
            title: intl.formatMessage({ id: "payment-process" }) + ` - ${lesson}`,
            html: price + `<br>` + `<br>` +
                (usePaypal? intl.formatMessage({ id: "payment-paypal-intro" }) : intl.formatMessage({ id: "payment-mercadopago-intro" })) +
                `<br>` + `<br>` +
                intl.formatMessage({ id: "google-account" }) +
                `<br>` + `<br>` +
                intl.formatMessage({ id: "accept-terms", defaultMessage: "By making the purchase, you accept the " }) +
                `<a href='/terms' class='mt-5 text-center underline hover:text-black'>` +
                intl.formatMessage({ id: "terms-and-conditions", defaultMessage: "Terms and conditions" }) + 
                `</a>.`,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: intl.formatMessage({ id: "payment-cancel" }),
            confirmButtonText: intl.formatMessage({ id: "payment-next" })
          }).then((result) => {
            if (result.isConfirmed) {
                MySwal.mixin({
                    input: 'text',
                    confirmButtonText: intl.formatMessage({ id: "payment-next" }),
                    showCancelButton: true,
                    progressSteps: ['1', '2'],
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (value.length > 1) {
                                resolve()
                            } else {
                                resolve(intl.formatMessage({ id: "payment-required-field" }))
                            }
                        })
                    }
                  }).queue([
                    {
                        title: intl.formatMessage({ id: "payment-name" }),
                    },
                    {
                        title: intl.formatMessage({ id: "payment-email" }),
                    }
                  ]).then((result) => {
                    if (result.value) {
                        const [name, email] = result.value
                        const data = {
                            lesson: lesson,
                            isPaypal: usePaypal,
                            name: name,
                            email: email
                        }
                        axios({
                            method: 'post',
                            url: endpoints.paymentEmail,
                            data: JSON.stringify(data),
                            dataType: 'json',
                            responseType: 'json'
                        }).then(response => {
                            MySwal.fire({
                                title: intl.formatMessage({ id: "payment-alert-almost-done" }),
                                showConfirmButton: false,
                                html: (usePaypal? intl.formatMessage({ id: "payment-paypal-last-step" }) : intl.formatMessage({ id: "payment-mercadopago-last-step" })),
                                footer: finalStepHtml
                              })
                        }).catch((error) => {
                            MySwal.fire({
                                title: intl.formatMessage({ id: "payment-alert-almost-done" }),
                                showConfirmButton: false,
                                html: (usePaypal? intl.formatMessage({ id: "payment-paypal-last-step" }) : intl.formatMessage({ id: "payment-mercadopago-last-step" })),
                                footer: finalStepHtml
                              })
                        })
                    }
                  })
            }
          })
    }

    // const RenderPaymentLink = (esArgentina) => {
    //     return (
    //         <a href={esArgentina.esArgentina? linkMercadopago : linkPaypal} 
    //             className='px-4 py-2 mx-2 mt-2 text-sm border-2 border-white md:text-base bg-flyer-blue hover:bg-white hover:text-flyer-blue'>
    //             {intl.formatMessage({ id: "buy-button" })}
    //         </a>
    //     )
    // }

    // const RenderVideoPaymentLink = (esArgentina) => {
    //     return (
    //         <a href={esArgentina.esArgentina? linkMercadopago : linkPaypal} 
    //             className='px-4 py-2 mx-2 mt-2 text-sm font-semibold bg-white border-2 text-flyer-blue border-flyer-blue md:text-base hover:bg-flyer-blue hover:text-white'>
    //             {intl.formatMessage({ id: "buy-button" })}          
    //         </a>
    //     )
    // }

    return (
        <div className='col-span-12 mx-1 my-1 overflow-hidden text-center text-white shadow-lg bg-flyer-blue sm:col-span-6 md:col-span-6 xl:col-span-3'>
            { Object.entries(image).length === 0? '' :
                <Img fluid={image}/>
            }
            <div className='px-8 pt-2 pb-4'>
                <h3 className='font-serif text-lg font-semibold md:text-xl'>{title}</h3>
                <p className='block mt-2 text-sm md:text-base'>
                    {description}
                </p>
                <div className='flex flex-row items-stretch justify-center mt-2'>
                    <button onClick={() => PaymentProcess(!esArgentina, title, linkPaypal, linkMercadopago, paypalPrice, mercadopagoPrice)}
                        className='px-4 py-2 mx-2 mt-2 text-sm border-2 border-white md:text-base bg-flyer-blue hover:bg-white hover:text-flyer-blue'>
                            {intl.formatMessage({ id: "buy-button" })}
                    </button>
                    {youtubeLink?
                        <VideoComponent 
                            // trailer={trailer} 
                            youtubeLink={youtubeLink}
                            title={title}>
                            <button onClick={() => PaymentProcess(!esArgentina, title, linkPaypal, linkMercadopago, paypalPrice, mercadopagoPrice)}
                                className='px-4 py-2 mx-2 mt-2 text-sm font-semibold bg-white border-2 text-flyer-blue border-flyer-blue md:text-base hover:bg-flyer-blue hover:text-white'>
                                   { intl.formatMessage({ id: "buy-button" })}
                            </button>
                        </VideoComponent>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

LessonCard.propTypes = {
    title: PropTypes.string,    
    description: PropTypes.string,
    image: PropTypes.object,
    //trailer: PropTypes.array,
    youtubeLink: PropTypes.string,
    linkMercadopago: PropTypes.string,
    linkPaypal: PropTypes.string,
    esArgentina: PropTypes.bool,
    paypalPrice: PropTypes.number,
    mercadopagoPrice: PropTypes.number
}

export default LessonCard

